<template>
  <div class="container">
    <div class="houses__wrapper screen__wrapper">
      <CardHouse
        v-for="renter in renters"
        :key="renter.id"
        :renter="renter"
        @click.native="info(renter)"
      />
    </div>
    <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="loadObj"></infinite-loading>
  </div>
</template>

<script>
import CardHouse from '@/views/Renters/Card'
import InfoRenter from '@/views/Renters/Info'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: "FavoriteRentersHouses",
  components: {
    CardHouse,
    InfiniteLoading
  },
  computed: {
    renters() {
      return this.$store.getters.rentersFavHouse;
    },
    hasMoreResults () {
      return this.renters.length < this.totalCount && this.page > 1
    },
    totalCount() {
      return this.$store.getters.paginationFavRentersHouse.total
    }, 
    page() {
      return this.$store.getters.pageFavRentersHouse
    },
    class() {
      return this.$route.meta.class
    }
  },
  data: () => ({
    isLoading: false,
  }),
  methods: {
    info(renter) {
      const style = this.$modal.styles
      this.$modal.show(
        InfoRenter, 
        {renter}, 
        {...style, name: 'InfoRenter'},
        {'before-open': this.$modal.open,'before-close': this.$modal.close}
        );
    },
    async loadObj() {
      this.isLoading = true
      try {
        await this.$store.dispatch("fetchFavoritesRenters", 'house')
        this.$emit('loaded')
      } finally {
        this.isLoading = false
      }
    },
  },
};
</script>
